import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Stepper, Step, StepLabel, StepConnector, Hidden, Container, Box } from '@mui/material';
import classNames from 'classnames';

import Button from '../Button/Button';
import { useStepperConnectorClasses, useStyles } from './Stepper.styles';
import { Typography } from '..';
import StepIcon from './StepIcon';
import { StepperProps } from './StepIcon.types';

const CustomStepper = (props: StepperProps) => {
	const {
		stepIds,
		stepsContent,
		backLocaleId,
		nextLocaleId,
		submitLocaleId,
		step,
		onStepChange,
		stepIcons,
		isMultipleStepsForm = false,
		loading,
		className,
		classes: classesProp = {},
		contentHeader,
		preStepsElement = null,
		...others
	} = props;
	const isNonControlledMode = typeof step === 'undefined';
	const classes = useStyles();
	const stepperConnectorClasses = useStepperConnectorClasses();
	const [currentStep, setCurrentStep] = useState(step || 0);
	const isLastStep = currentStep === stepIds.length - 1;
	const buttonsAvailable = backLocaleId || nextLocaleId || submitLocaleId;

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (typeof step === 'number' && step !== currentStep) {
			setCurrentStep(step);
			if (typeof window !== 'undefined' && containerRef.current) {
				const scrollPosition = containerRef.current.scrollTop;
				window.scrollTo(0, scrollPosition);
			}
		}
	}, [step]);

	const changeStep = (nextStep: number) => {
		isNonControlledMode && setCurrentStep(nextStep);
		onStepChange && onStepChange(nextStep);
	};

	const handleNext: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		if (!isLastStep) {
			const nextStep = currentStep + 1;
			changeStep(nextStep);
		}
	};

	const handleBack: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		if (currentStep > 0) {
			const previousStep = currentStep - 1;
			changeStep(previousStep);
		}
	};

	return (
		<div className={classNames(classes.container, className)} ref={containerRef}>
			<Box className={classesProp.stepperContainer}>
				<Container>
					<Hidden mdDown implementation="css">
						<Box className={classes.wrappedDesktopContainer}>
							<Stepper
								nonLinear
								activeStep={currentStep}
								classes={{ root: classes.root }}
								className={classesProp.stepper}
								{...others}
							>
								{preStepsElement}
								{stepIds.map((stepId, index) => (
									<Step
										className={classNames(classes.step, {
											[classes.firstStep]: index === 0,
											[classes.firstStepExtraSpace]:
												index === 0 && preStepsElement != null
										})}
										key={stepId}
									>
										{index > 0 ? (
											<StepConnector classes={stepperConnectorClasses} />
										) : null}
										<StepLabel
											onClick={() => changeStep(index)}
											className={classes.stepLabelContainer}
											classes={{
												labelContainer: classes.stepLabelLabelContainer
											}}
											StepIconComponent={
												stepIcons
													? () => {
															const icon = stepIcons[index];
															return icon ? (
																<StepIcon icon={icon} />
															) : (
																<>{index}</>
															);
													  }
													: () => (
															<div
																className={classNames(
																	classes.ball,
																	{
																		[classes.currentBall]:
																			currentStep === index
																	}
																)}
															>
																{index + 1}
															</div>
													  )
											}
										>
											<Typography
												className={classNames(classes.stepLabel, {
													[classes.currentStepLabel]:
														currentStep === index
												})}
												localeId={stepId}
											/>
										</StepLabel>
									</Step>
								))}
							</Stepper>
						</Box>
					</Hidden>
					<Hidden mdUp implementation="css">
						<Typography
							className={classNames(classes.stepLabel, classesProp.stepper)}
							localeId="common.stepper.mobile-title"
							localeValues={{
								step: (step || 0) + 1,
								numberOfSteps: stepIds.length
							}}
						/>
					</Hidden>
				</Container>
			</Box>
			{stepsContent && stepsContent.length > 0 && (
				<div className={classesProp.content}>
					<div>
						{contentHeader}
						{isMultipleStepsForm
							? stepsContent.map((stepContent, index) => {
									return (
										<div
											key={index}
											className={`${
												currentStep !== index ? classes.hidden : ''
											}`}
										>
											{stepContent}
										</div>
									);
							  })
							: stepsContent[currentStep]}
						{buttonsAvailable && (
							<div className={classes.buttonContainer}>
								{backLocaleId && (
									<Button
										disabled={currentStep === 0}
										onClick={handleBack}
										className={classes.button}
										color="secondary"
										localeId={backLocaleId}
									/>
								)}
								{isLastStep && submitLocaleId && (
									<Button
										type="submit"
										disabled={loading}
										color="secondary"
										localeId={submitLocaleId}
									/>
								)}
								{!isLastStep && nextLocaleId && (
									<Button
										disabled={isLastStep}
										onClick={handleNext}
										className={classes.button}
										localeId={nextLocaleId}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default CustomStepper;
