import dayjs, { ConfigType, Dayjs } from 'dayjs';
import {
	PickedDate,
	PickedRangeDate,
	RangePickerProps,
	SinglePickerProps,
	PickerVariant,
	SingleDate,
	RangeDate
} from './DateRangePicker.types';
import { DateFormat, formatDateForDB, parseDBDate } from '../utils/dates';
import IMask from 'imask';

export const delimiter = ` — `;
export const displayFormat = DateFormat.date;
export const rangeDisplayFormat = `${displayFormat}${delimiter}${displayFormat}`;
type onSingleDateChange = (value: string) => void;
type onRangeDateChange = (value: { start: string; end: string }) => void;

type DateMaskFactoryProps = {
	input: HTMLInputElement;
	minDate: Dayjs | undefined;
	maxDate: Dayjs | undefined;
};

const startOrEnd = (date: Dayjs, endOfDay: boolean | undefined): Dayjs =>
	endOfDay ? date.endOf('day') : date;

export const isSinglePicked = (pickedDate: PickedRangeDate): pickedDate is SingleDate => {
	const singlePickedDate = pickedDate as SingleDate;
	return singlePickedDate.single === null || dayjs.isDayjs(singlePickedDate.single);
};

export const isSingleVariantProps = (
	props: SinglePickerProps | RangePickerProps
): props is SinglePickerProps => props.variant === PickerVariant.SINGLE;

const transformValueToDisplayString = (value: ConfigType): string => {
	const dateValue = parseDBDate(value);
	const result = dateValue.isValid() ? dateValue.format(displayFormat) : '';
	return result;
};

export const transformValueToDate = (value: ConfigType): PickedDate => {
	const dateValue = parseDBDate(value);
	const result = dateValue.isValid() ? dateValue : null;
	return result;
};

const transformDateToDisplayString = (value: PickedDate): string => {
	const result = value?.isValid() ? value.format(displayFormat) : '';
	return result;
};

const transformDisplayStringToDate = (
	value: string | undefined,
	endOfDay?: boolean
): PickedDate => {
	const date = dayjs(value, displayFormat);
	const result = date.isValid() ? startOrEnd(date, endOfDay) : null;
	return result;
};

const transformDisplayStringToValue = (value: string, endOfDay?: boolean): string => {
	const date = dayjs(value, displayFormat);
	const processedDate = startOrEnd(date, endOfDay);
	const result = formatDateForDB(processedDate);
	return result;
};

const transformDateToValue = (date: PickedDate): string => {
	const result = date?.isValid() ? formatDateForDB(date) : '';
	return result;
};

const createBaseMaskConfig = (props: {
	minDate: Dayjs | undefined;
	maxDate: Dayjs | undefined;
}) => ({
	mask: Date,
	lazy: false,

	min: props.minDate?.toDate(),
	max: props.maxDate?.toDate(),

	blocks: {
		DD: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 31,
			maxLength: 2,
			placeholderChar: 'D'
		},
		MM: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 12,
			maxLength: 2,
			placeholderChar: 'M'
		},
		YYYY: {
			mask: IMask.MaskedRange,
			from: 1900,
			to: 9999,
			placeholderChar: 'Y'
		}
	}
});

export const RangeDateUtils = {
	transformValueToDisplayString: (variantProps: RangePickerProps): string => {
		const start = transformValueToDisplayString(variantProps.value.start);
		const end = transformValueToDisplayString(variantProps.value.end);

		if (!start) {
			return '';
		}

		return `${start}${delimiter}${end}`;
	},
	transformValueToDate: (variantProps: RangePickerProps): RangeDate => {
		const start = transformValueToDate(variantProps.value.start);
		const end = transformValueToDate(variantProps.value.end)?.endOf('day') || null;

		return {
			start,
			end
		};
	},
	createOnChange: (onChange: onRangeDateChange) => (pickedDateRange: PickedRangeDate) => {
		if (isSinglePicked(pickedDateRange)) {
			return;
		}
		const { start, end } = pickedDateRange;

		onChange({
			start: transformDateToValue(start),
			end: transformDateToValue(end)
		});
	},
	createOnClear: (onChange: onRangeDateChange) => () => onChange({ start: '', end: '' }),
	createDateMask: (props: DateMaskFactoryProps) =>
		IMask(props.input, {
			...createBaseMaskConfig(props),

			pattern: rangeDisplayFormat,

			format: (value: RangeDate): string => {
				const { start, end } = value;
				if (!start) {
					return '';
				}
				const startString = transformDateToDisplayString(start);
				const endString = transformDateToDisplayString(end);
				const result = `${startString}${delimiter}${endString}`;

				return result;
			},
			parse: (str: string): RangeDate => {
				const [start, end] = str.split(delimiter);
				const startDate = transformDisplayStringToDate(start);
				const endDate = transformDisplayStringToDate(end)?.endOf('day') || null;

				return {
					start: startDate,
					end: endDate
				};
			}
		}),
	createOnMaskComplete: (variantProps: RangePickerProps) => (displayString: string) => {
		const [startDate, endDate] = displayString
			.split(delimiter)
			.map((date, index) => transformDisplayStringToDate(date, index === 1));

		const isRevert = startDate && endDate && !endDate.isAfter(startDate, 'day');

		const result = isRevert
			? {
					start: transformDateToValue(endDate?.startOf('day') || null),
					end: transformDateToValue(startDate?.endOf('day') || null)
			  }
			: {
					start: transformDateToValue(startDate),
					end: transformDateToValue(endDate)
			  };

		variantProps.onChange(result);
	}
};

export const SingleDateUtils = {
	transformValueToDisplayString: (variantProps: SinglePickerProps): string => {
		const result = transformValueToDisplayString(variantProps.value);
		return result;
	},
	transformValueToDate: (variantProps: SinglePickerProps): SingleDate => {
		const date = transformValueToDate(variantProps.value);
		const result = { single: date };
		return result;
	},
	createOnChange: (onChange: onSingleDateChange) => (pickedDateRange: PickedRangeDate) => {
		if (!isSinglePicked(pickedDateRange)) {
			return;
		}
		const { single } = pickedDateRange;
		onChange(transformDateToValue(single));
	},
	createOnClear: (onChange: onSingleDateChange) => () => onChange(''),
	createDateMask: (props: DateMaskFactoryProps) =>
		IMask(props.input, {
			...createBaseMaskConfig(props),

			pattern: displayFormat,

			format: (date: PickedDate): string => {
				const result = transformDateToDisplayString(date);
				return result;
			},
			parse: (str: string): PickedDate => {
				const result = transformDisplayStringToDate(str);
				return result;
			}
		}),
	createOnMaskComplete: (variantProps: SinglePickerProps) => (value: string) => {
		const result = transformDisplayStringToValue(value);
		variantProps.onChange(result);
	}
};
