import { MessageFormatElement } from 'react-intl';
import { ContentTransformers, formatters, Types } from '../..';

export const formatAddToCalendarTitle = (
	content: {
		contentType: Types.ContentType | null | undefined;
		title?: string | null | undefined;
		brand?: Types.ProductBrand | null | undefined;
	},
	messages: Record<string, string> | Record<string, Array<MessageFormatElement>>
) => {
	return `${formatters.formatEnum(content.contentType, {
		messages,
		options: ContentTransformers.contentType
	})} ${formatters.formatEnum(content.brand, {
		messages,
		options: ContentTransformers.brand
	})} - ${content.title}`;
};
