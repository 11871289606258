import { MenuItem, MenuLinkItem } from './DrawerMenu.types';
import type { ThemeTypes } from '../';

export const getDefaultMenuState = (theme: ThemeTypes.Theme) => {
	const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
	return windowWidth >= theme.breakpoints.values.lg;
};

export const isDesktop = (theme: ThemeTypes.Theme) => {
	const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
	return windowWidth >= theme.breakpoints.values.md;
};

export const isMenuLinkItem = (menuItem: MenuItem): menuItem is MenuLinkItem => {
	const menuLinkItem = menuItem as MenuLinkItem | null | undefined;
	if (!menuLinkItem) {
		return false;
	}
	return (
		menuLinkItem &&
		(!!menuLinkItem.localeId ||
			!!menuLinkItem.text ||
			!!menuLinkItem.href ||
			!!menuLinkItem.cta ||
			!!menuLinkItem.icon ||
			!!menuLinkItem.iconName ||
			!!menuLinkItem.iconUrl)
	);
};
