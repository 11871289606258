import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import classNames from 'classnames';

import { DropdownProps } from './Dropdown.types';
import useStyles from './Dropdown.styles';
import Typography from '../Typography/Typography';
import type { SelectableOption } from '../utils/componentsTypes';
import { TextField } from '../TextField';

const renderItem = (option: SelectableOption, onChange: DropdownProps['onChange']) => (
	<MenuItem onClick={() => onChange(option.id)} value={option.id} key={option.id}>
		<Typography localeId={option.localeId} title={option.label} />
	</MenuItem>
);

const Dropdown = (props: DropdownProps) => {
	const {
		value,
		options = [],
		onChange,
		error,
		name,
		required,
		readOnly,
		disabled,
		title,
		titleLocaleId,
		titleLocaleValues,
		minimal = false,
		open: openProp,
		classes: classesProp = {},
		className,
		startIcon,
		...others
	} = props;
	const classes = useStyles(props);
	const [open, setOpen] = useState(false);

	const handleChange = (option: string | null) => {
		const newValue = option === value ? null : option;
		onChange(newValue);
		setOpen(false);
	};

	const isSelected = Boolean(value?.length);

	const allOptions = options.flatMap((option) => option);
	const numberOfSections = options.length;

	return (
		<FormControl variant="outlined" className={className}>
			{!value && (title || titleLocaleId) && (
				<InputLabel
					className={classNames(classes.label, {
						[classes.selectedLabel]: isSelected
					})}
				>
					{typeof title === 'string' || typeof titleLocaleId === 'string' ? (
						<Typography
							localeId={titleLocaleId}
							localeValues={titleLocaleValues}
							title={title}
							variant="textSmall"
						/>
					) : (
						title
					)}
				</InputLabel>
			)}
			<TextField
				classes={{
					root: classNames({
						[classes.minimalTextField]: minimal
					}),
					inputBaseRoot: classes.inputBaseRoot,
					input: classes.input,
					notchedOutline: classes.notchedOutline
				}}
				InputProps={{
					...(value
						? {
								classes: {
									notchedOutline: classes.textFieldOutlined
								}
						  }
						: {}),
					endAdornment: null
				}}
				size="small"
				select
				name={name}
				value={value || ''}
				label=""
				hideClearIcon
				SelectProps={{
					open,
					onOpen: () => setOpen(true),
					onClose: () => setOpen(false),
					variant: minimal ? 'standard' : undefined,
					renderValue: (value) => {
						const option = allOptions.find((o) => o.id === value);
						return option ? (
							<>
								{startIcon}
								<Typography
									className={classNames(classes.text, {
										[classes.minimalText]: minimal
									})}
									title={option.label}
									localeId={minimal ? titleLocaleId : option.localeId}
									localeValues={titleLocaleValues}
									variant="textSmall"
								/>
							</>
						) : null;
					},
					children: options.map((sectionOrOption, sectionIndex) =>
						Array.isArray(sectionOrOption)
							? sectionOrOption.map((option) => (
									<div
										className={classNames({
											[classes.sectionBorder]:
												sectionIndex + 1 < numberOfSections
										})}
										key={sectionIndex}
									>
										{renderItem(option, handleChange)}
									</div>
							  ))
							: renderItem(sectionOrOption, handleChange)
					),
					classes: {
						...classes,
						...classesProp,
						icon: classNames(isSelected ? classes.iconSelected : classes.icon, {
							[classes.iconMinimal]: minimal
						}),
						outlined: isSelected ? classes.selected : classes.outlined
					},
					...others
				}}
			/>
		</FormControl>
	);
};

export default Dropdown;
