import React, { useEffect, useState, useRef } from 'react';
import { Box, List, ListItem, useMediaQuery, useTheme } from '@mui/material';
import _debounce from 'lodash/debounce';

import useStyles from './HorizontalList.styles';
import { HorizontalListProps } from './HorizontalList.types';
import { Icon } from '../Icon';

const HorizontalList = <T extends { id: string }>(props: HorizontalListProps<T>) => {
	const {
		items: itemsProps,
		renderItem,
		offset = 0,
		numberOfItemsInRow,
		onOffsetChange,
		totalCount: totalCountProp,
		isSkeletonVisible = false,
		renderSkeletonItem = () => null,
		renderEmptyList,
		listRef: listRefProp
	} = props;
	const classes = useStyles(props);
	const [items, setItems] = useState<Array<T>>(itemsProps);
	const itemRef = useRef<HTMLLIElement | null>(null);
	const listRef = useRef<HTMLUListElement | null>(null);
	const theme = useTheme();
	const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'), {
		defaultMatches: !theme.isMobile ?? false
	});

	const totalCount = totalCountProp ? totalCountProp : itemsProps?.length || 0;

	const handlePrevious = () => {
		if (onOffsetChange && offset >= numberOfItemsInRow) {
			onOffsetChange(offset - numberOfItemsInRow);
		}
	};

	const areMoreItemsAvailable = () =>
		totalCount != null ? offset + numberOfItemsInRow < totalCount : false;

	const handleNext = () => {
		if (areMoreItemsAvailable() && onOffsetChange) {
			onOffsetChange(offset + numberOfItemsInRow);
		}
	};

	const _handleScroll = () => {
		if (isDesktopVersion) {
			return;
		}

		if (!listRef.current || !itemRef.current) {
			return;
		}

		const windowWidth = window.innerWidth;
		const scrollWidth = listRef.current.scrollWidth;
		const scrollLeft = listRef.current.scrollLeft;

		// Multiply by number of items per row to ancitipate the user scroll and go to the next page earlier
		if (numberOfItemsInRow * windowWidth + scrollLeft >= scrollWidth) {
			handleNext();
		}
	};

	useEffect(() => {
		if (!itemsProps.length) {
			setItems([]);
		} else if (isDesktopVersion) {
			setItems(itemsProps);
		} else {
			const previousScrollPosition = listRef.current?.scrollLeft ?? 0;
			setItems([
				...items,
				/**
				 * WARNING: The following filter is useful for avoiding duplicates. Note that we are looping
				 * the array multiple times because the "every" is a nested iteration for each item. This could
				 * cause performance issues if this component is used on mobile version with hundreds of items.
				 */
				...itemsProps.filter((item) =>
					items.every((currentItem) => currentItem.id !== item.id)
				)
			]);
			_debounce(() => {
				if (listRef.current) {
					listRef.current.scrollLeft = previousScrollPosition;
				}
			}, 0)();
		}
	}, [itemsProps]);

	const handleScroll = _debounce(_handleScroll, 300);

	const handleRenderItem = (isSkeleton: boolean) => (item: T, index: number) =>
		(
			<ListItem
				className={classes.item}
				style={{
					...(isDesktopVersion
						? { maxWidth: `calc(${100 / numberOfItemsInRow}%)` }
						: undefined)
				}}
				key={item.id}
				ref={itemRef}
			>
				{isSkeleton ? renderSkeletonItem() : renderItem(item, index)}
			</ListItem>
		);

	const handleRenderList = () => {
		if (isSkeletonVisible) {
			return (
				<List className={classes.list}>
					{Array.from(Array(numberOfItemsInRow).keys()).map((_element, index) =>
						handleRenderItem(isSkeletonVisible)({ id: index.toString() } as T, index)
					)}
				</List>
			);
		} else if (items.length > 0) {
			const slicedItems =
				!isDesktopVersion || items.length <= numberOfItemsInRow
					? items
					: items.slice(offset, offset + numberOfItemsInRow);
			return (
				<List ref={listRef} onScroll={handleScroll} className={classes.list}>
					{slicedItems.map((element, index) =>
						handleRenderItem(isSkeletonVisible)(element, index)
					)}
					{!isDesktopVersion &&
						areMoreItemsAvailable() &&
						handleRenderItem(true)({ id: '1' } as T, items.length)}
				</List>
			);
		} else if (renderEmptyList) {
			return renderEmptyList();
		}
	};

	return (
		<Box className={classes.horizontalListContainer}>
			{isDesktopVersion && (
				<Box className={classes.iconContainer}>
					{offset > 0 && (
						<Icon
							strokeWidth={2}
							className={classes.icon}
							onClick={handlePrevious}
							icon="caretLeft"
						/>
					)}
				</Box>
			)}
			<div className={classes.listRefContainer} ref={listRefProp}>
				{handleRenderList()}
			</div>
			{isDesktopVersion && (
				<Box className={classes.iconContainer}>
					{offset + numberOfItemsInRow < totalCount && (
						<Icon
							strokeWidth={2}
							className={classes.icon}
							onClick={handleNext}
							icon="caretRight"
						/>
					)}
				</Box>
			)}
		</Box>
	);
};

export default HorizontalList;
