import { isArray, isString } from 'lodash';
import { QueryParamsItem, QueryParamsType } from '../contexts';

const normalizeQueryParam = (item: QueryParamsItem): string => {
	if (!item) {
		return '';
	}
	if (isArray(item)) {
		return JSON.stringify(item);
	}
	if (isString(item)) {
		return item;
	}
	return '';
};

const queryToUrlSearchParams = (query: QueryParamsType): URLSearchParams => {
	const normalized = query
		? Object.fromEntries(
				Object.entries(query).map(([key, value]) => {
					return [key, normalizeQueryParam(value)];
				})
		  )
		: undefined;
	return new URLSearchParams(normalized);
};

export default queryToUrlSearchParams;
