import { Components, Shadows } from '@mui/material/styles';
import type { ThemeTypes } from 'components';

const globalStyles = (theme: ThemeTypes.Theme) =>
	({
		...(theme.components?.MuiCssBaseline
			? theme.components?.MuiCssBaseline
			: {
					html: {
						height: '100%'
					},
					body: {
						backgroundColor: theme.palette.common.white,
						[theme.breakpoints.up('sm')]: {
							height: '100%'
						}
					},
					'#__next': {
						height: '100%'
					}
			  })
	} as Components['MuiCssBaseline']);

const getCatalogTheme = (theme: ThemeTypes.Theme): ThemeTypes.Theme => ({
	...theme,
	components: {
		...(theme.components || {}),
		MuiCssBaseline: {
			...theme.components?.MuiCssBaseline,
			...globalStyles(theme)
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					[theme.breakpoints.up('sm')]: {
						maxWidth: 1280
					},
					[theme.breakpoints.up('xl')]: {
						maxWidth: 1440
					}
				}
			}
		}
	},
	headerHeight: {
		lg: 137,
		sm: 80
	},
	shadows: ['none', '0px 4px 36px rgba(0, 0, 0, 0.03)', ...Array(23).fill('none')] as Shadows
});

export default getCatalogTheme;
