// TODO: use the appropriate fallback icon for topic.
export { default as addon } from './addon.svg';
export { default as amex } from './american-express.svg';
export { default as apps } from './apps.svg';
export { default as arrowLeft } from './arrow-left.svg';
export { default as arrowRight } from './arrow-right.svg';
export { default as arrowRightType2 } from './arrow-right-type2.svg';
export { default as arrowUp } from './arrow-up.svg';
export { default as arrowOutward } from './arrow-outward.svg';
export { default as arrowOutward2 } from './arrow-outward-2.svg';
export { default as book } from './book.svg';
export { default as bookOpen } from './book-open.svg';
export { default as calendar } from './calendar.svg';
export { default as calendarBlank } from './calendar-blank.svg';
export { default as caretDown } from './caret-down.svg';
export { default as caretLeft } from './caret-left.svg';
export { default as caretRight } from './caret-right.svg';
export { default as checkCircle } from './check-circle.svg';
export { default as checkmark } from './checkmark.svg';
export { default as checkmarkRound } from './checkmark-round.svg';
export { default as checkmarkLight } from './checkmark-light.svg';
export { default as circle } from './circle.svg';
export { default as circleFill } from './circleFill.svg';
export { default as circleWavyCheck } from './circle-wavy-check.svg';
export { default as clock } from './clock.svg';
export { default as close } from './close.svg';
export { default as course } from './course.svg';
export { default as creditCard } from './credit-card.svg';
export { default as cross } from './cross.svg';
export { default as domain } from './domain.svg';
export { default as dots } from './dots.svg';
export { default as download } from './download.svg';
export { default as eco } from './eco.svg';
export { default as error } from './error.svg';
export { default as exclamation } from './exclamation.svg';
export { default as eye } from './eye.svg';
export { default as eyeClosed } from './eye-closed.svg';
export { default as filters } from './filters.svg';
export { default as fomfShape } from './fomf-shape.svg';
export { default as freeContent } from './free-content.svg';
export { default as guidelines } from './guidelines.svg';
export { default as help } from './help.svg';
export { default as home } from './home.svg';
export { default as hybrid } from './hybrid.svg';
export { default as info } from './info.svg';
export { default as infoSolid } from './info-solid.svg';
export { default as kardiologie } from './kardiologie.svg';
export { default as language } from './language.svg';
export { default as lecture } from './lecture.svg';
export { default as listBullets } from './list-bullets.svg';
export { default as livestream } from './livestream.svg';
export { default as login } from './login.svg';
export { default as magnifyingGlass } from './magnifying-glass.svg';
export { default as mapPin } from './map-pin.svg';
export { default as mastercard } from './mastercard.svg';
export { default as menu } from './menu.svg';
export { default as monitorPlay } from './monitor-play.svg';
export { default as onsite } from './onsite.svg';
export { default as pause } from './pause.svg';
export { default as paypal } from './paypal.svg';
export { default as pencil } from './pencil.svg';
export { default as phone } from './phone.svg';
export { default as play } from './play.svg';
export { default as plus } from './plus.svg';
export { default as plusCircle } from './plus-circle.svg';
export { default as printer } from './printer.svg';
export { default as refresher } from './refresher.svg';
export { default as search } from './search.svg';
export { default as sepa } from './sepa.svg';
export { default as simpleEnvelope } from './simple-envelope.svg';
export { default as skills } from './skills.svg';
export { default as sms } from './sms.svg';
export { default as success } from './success.svg';
export { default as successCircle } from './success-circle.svg';
export { default as successSolid } from './success-solid.svg';
export { default as summedUp } from './summed-up.svg';
export { default as textAlignCenter } from './text-align-center.svg';
export { default as ticket } from './ticket.svg';
export { default as topicGeneric } from './topic-generic.svg';
export { default as trash } from './trash.svg';
export { default as user } from './user.svg';
export { default as userGear } from './user-gear.svg';
export { default as videoCamera } from './video-camera.svg';
export { default as visa } from './visa.svg';
export { default as warning } from './warning.svg';
export { default as warningTriangle } from './warning-triangle.svg';
export { default as warningTriangleOrange } from './warning-triangle-orange.svg';
export { default as webUp } from './web-up.svg';
export { default as facebook } from './facebook.svg';
export { default as linkedin } from './linkedin.svg';
export { default as website } from './website.svg';
export { default as twitter } from './twitter.svg';
export { default as youtube } from './youtube.svg';
export { default as instagram } from './instagram.svg';
export { default as xing } from './xing.svg';
export { default as pdfIcon } from './pdf-icon.svg';
export { default as brands } from './brands.svg';
export { default as invoice } from './invoice.svg';
export { default as location } from './location.svg';
export { default as program } from './program.svg';
export { default as infoBlack } from './info-black.svg';
export { default as playCircle } from './play-circle.svg';
export { default as playCircle2 } from './play-circle2.svg';
export { default as certificate } from './certificate.svg';
export { default as certificationPending } from './certification-pending.svg';
export { default as certificationDone } from './certification-done.svg';
export { default as downloadWhite } from './download-white.svg';
export { default as receiptLong } from './recepit-long.svg';
export { default as verified } from './verified.svg';
export { default as refreshArrows } from './refresh-arrows.svg';
export { default as spinner } from './spinner.svg';
export { default as progressPlay } from './progress-play.svg';
export { default as twint } from './twint.svg';
export { default as syfat } from './syfat.svg';
