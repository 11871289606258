import React from 'react';
import Dayjs from 'dayjs';
import classNames from 'classnames';
import { Container, Divider, useMediaQuery, useTheme, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import {
	CONTACTS_LINKS,
	TERMS_AND_CONDITIONS_LINKS,
	IMPRESSUM_LINKS,
	PRIVACY_LINKS,
	SUPPORT_EMAIL_ADDRESS,
	useLocaleData,
	NextLink
} from '../../';
import getLocalizedLink from '../../utils/getLocalizedLink';
import useStyles from './Footer.styles';
import { Logo, Button, Typography, Icon, Link, FormikTextField, useCustomFormik } from 'components';
import useChangeURL from '../../hooks/useChangeURL';
import { USER_URLS } from '../../urls';
import LocaleSwitch from '../Header/LocaleSwitch';
import * as yup from 'yup';

export const getLinks = (locale: string) => ({
	contacts: `/kontakt-${locale}`,
	agb: '/agb'
});

const Footer = () => {
	const { formatMessage, locale } = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const { domain, countryCode } = useLocaleData();
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const changeURL = useChangeURL();

	const impressumLink = getLocalizedLink(IMPRESSUM_LINKS, locale);
	const supportEmail: string = SUPPORT_EMAIL_ADDRESS[domain];

	const formik = useCustomFormik({
		initialValues: {
			email: ''
		},
		validationSchema: yup.object({
			email: yup.string().email('common.valid-email').required('common.error.required-email')
		}),
		onSubmit: async (values) => {
			const { email } = values;
			const encodedEmail = encodeURIComponent(email);
			changeURL(USER_URLS.registration({ email: encodedEmail }), 'catalog');
		}
	});

	return (
		<div className={classes.footer}>
			<div>
				<Container maxWidth="xl">
					<div className={classes.columnsContainer}>
						<div className={classes.logoContainer}>
							<div>
								<Logo
									locale={locale}
									fill="#D6DEE5"
									className={classes.logo}
									type="compact"
								/>
							</div>
						</div>
						<div className={classes.firstColumn}>
							<div className={classes.columnItem}>
								<Icon icon="phone" className={classes.icon} />
								<Typography
									className={classes.columnText}
									variant="textXSmall"
									localeId="components.footer.phone"
									color="body"
								/>
							</div>
							{countryCode !== 'ch' && (
								<div className={classes.columnItem}>
									<Icon icon="printer" className={classes.icon} />
									<Typography
										localeId="components.footer.fax"
										variant="textXSmall"
										className={classes.columnText}
										color="body"
									/>
								</div>
							)}
							<div className={classes.columnItem}>
								<Icon icon="simpleEnvelope" className={classes.icon} />
								<Link href={`mailto:${supportEmail}`} target="_blank">
									<Typography
										title={supportEmail}
										variant="textXSmall"
										className={classes.columnText}
										color="body"
									/>
								</Link>
							</div>
							<div className={classes.columnItem}>
								<Icon icon="mapPin" className={classes.icon} />
								<Typography
									localeId="components.footer.address"
									variant="textXSmall"
									className={classes.columnText}
									color="body"
								/>
							</div>
						</div>
						<Divider />
						<div className={classes.secondColumn}>
							<div className={classes.socialIcons}>
								<Link
									href={formatMessage({ id: 'common.footer.facebook' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="facebook" className={classes.socialIcon} />
								</Link>
								<Link
									href={formatMessage({ id: 'common.footer.linkedin' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="linkedin" className={classes.socialIcon} />
								</Link>
								<Link
									href={formatMessage({ id: 'common.footer.instagram' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="instagram" className={classes.socialIcon} />
								</Link>
								<Link
									href={formatMessage({ id: 'common.footer.xing' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="xing" className={classes.socialIcon} />
								</Link>
							</div>
							<Divider />
							<div className={classes.contactLinks}>
								<a
									href={getLocalizedLink(CONTACTS_LINKS, locale)}
									className={classes.link}
								>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.contact"
										variant="textXSmall"
										color="secondary"
									/>
								</a>
								<NextLink
									href={getLocalizedLink(TERMS_AND_CONDITIONS_LINKS, locale)}
									className={classes.link}
								>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.agb"
										variant="textXSmall"
										color="secondary"
									/>
								</NextLink>
								{impressumLink && (
									<a href={impressumLink} className={classes.link}>
										<Typography
											className={classes.linkText}
											localeId="components.footer.link.impressum"
											variant="textXSmall"
											color="secondary"
										/>
									</a>
								)}
								<a
									href={getLocalizedLink(PRIVACY_LINKS, locale)}
									className={classes.link}
								>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.privacy-policy"
										variant="textXSmall"
										color="secondary"
									/>
								</a>
							</div>
						</div>
						<Divider
							orientation={isDesktop ? 'vertical' : 'horizontal'}
							flexItem={isDesktop}
							className={classes.verticalDivider}
						/>
						<div>
							<Typography
								localeId="components.footer.newsletter.title"
								color="body"
								variant="textSmall"
								className={classes.newsletterTitle}
								fontFamily="Poppins"
							/>
							<Typography
								localeId="components.footer.newsletter.description"
								color="label"
								variant="textXXSmall"
								className={classes.newsletterDescription}
							/>
							<form className={classes.form} onSubmit={formik.handleSubmit}>
								<FormikTextField
									name="email"
									type="email"
									localeId="catalog.home.newsletter.email"
									formik={formik}
									hideClearIcon
									required={false}
								/>
								<Button
									className={classes.submitButton}
									type="submit"
									disabled={formik.isSubmitting}
									variant="outlined"
									color="secondary"
									localeId="common.footer.newsletter.submit"
								/>
							</form>
						</div>
					</div>
				</Container>
				<Box
					className={classNames(classes.copyrightContainer, {
						[classes.chCopyrightContainer]: countryCode === 'ch'
					})}
				>
					<Container className={classes.copyrightInnerContainer}>
						<Box className={classes.localeSwitchContainer}>
							<LocaleSwitch />
						</Box>
						<Typography
							localeId="components.footer.copyright"
							localeValues={{
								year: Dayjs().year(),
								lineBreak: <br className={classes.lineBreak} />
							}}
							className={classNames(classes.copyright, {
								[classes.chCopyright]: countryCode === 'ch'
							})}
							variant="textLarge"
						/>
					</Container>
				</Box>
			</div>
		</div>
	);
};

export default Footer;
