import { useState } from 'react';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { CONTACTS_LINKS } from '../../constants';
import { useRouter } from '../../hooks';
import { USER_URLS, MEDIATHEK_URLS, CATALOG_URLS, BOOKING_URLS } from '../../urls';
import createURL from '../../utils/createURL';
import { HeaderLinksProps } from './HeaderLinks.types';
import isFeatureEnabled, { Features } from '../../utils/isFeatureEnabled';
import getLocalizedLink, {
	getKeyForLocalizedLink,
	LocalizedLink
} from '../../utils/getLocalizedLink';
import { HeaderQueryParams } from '.';

const webupUrlByDomain: LocalizedLink = {
	de: `/webup-de`,
	'de-CH': `/webup`,
	'fr-CH': `/webup`,
	at: `/webup-at`,
	uk: `/webup-uk`
};

const scientificBoardPath = `wissenschaftliches_kollegium`;
const scientificBoardURL = `/${scientificBoardPath}`;
const scientificBoardUrlByDomain: LocalizedLink = {
	de: scientificBoardURL,
	'de-CH': scientificBoardURL,
	'fr-CH': `/fr/${scientificBoardPath}`,
	at: scientificBoardURL
};

const partnerUrl = `/partner`;
const partnerUrlByDomain: LocalizedLink = {
	de: partnerUrl,
	'de-CH': partnerUrl,
	'fr-CH': `/partner`,
	at: partnerUrl
};

const faqUrlByDomain: LocalizedLink = {
	de: `/faqs-deutschland`,
	'de-CH': `/FAQ`,
	'fr-CH': `/fr/FAQ`,
	at: `/FAQ_AT`
};

export const cmeCoursesUrlByDomain: LocalizedLink = {
	de: `/product/all`,
	'de-CH': `/product/all`,
	'fr-CH': `/product/all`,
	at: `/product/all`,
	uk: `/all-events`
};

export const cmeOnDemandCoursesUrlByDomain: LocalizedLink = {
	de: '/on-demand',
	'de-CH': '/on-demand',
	'fr-CH': '/on-demand',
	at: '/on-demand',
	uk: '/on-demand'
};

const livestreamURLByDomain: LocalizedLink = {
	de: `/livestream`,
	at: `/livestream`,
	'de-CH': `/livestream`,
	'fr-CH': `/livestream`
};

export const contactLink = (locale: Locale) => getLocalizedLink(CONTACTS_LINKS, locale);
export const faqLink = (locale: Locale) => getLocalizedLink(faqUrlByDomain, locale);

const getHeaderLink = ({
	url,
	localeId,
	locale,
	mediaQuery,
	showWhenFeatureAvailable,
	hideWhenFeatureAvailable,
	showWhenFeatureFlagAvailable
}: {
	url: LocalizedLink;
	localeId: string;
	locale: Locale;
	showWhenFeatureAvailable?: keyof Features;
	showWhenFeatureFlagAvailable?: (flags: LDFlagSet) => boolean;
	hideWhenFeatureAvailable?: keyof Features;
	mediaQuery?: 'desktop' | 'mobile';
}): HeaderLinksProps['menuItems'][0] => {
	const domainForLink = getKeyForLocalizedLink(locale);
	if (url[domainForLink as DomainForLinks]) {
		return [
			{
				localeId,
				href: getLocalizedLink(url, locale),
				showWhenFeatureAvailable,
				hideWhenFeatureAvailable,
				mediaQuery,
				showWhenFeatureFlagAvailable
			}
		];
	}
	return [];
};

const getDefaultLinks = (locale: Locale): HeaderLinksProps['menuItems'] => [
	[
		{
			localeId: 'common.header.home-site',
			href: CATALOG_URLS.base,
			showWhenFeatureAvailable: 'mediathek',
			iconUrl: 'https://storage.googleapis.com/bk-fms-eu/public/static/icon-home-page.svg'
		}
	],
	[
		...getHeaderLink({
			url: cmeCoursesUrlByDomain,
			localeId: 'common.header.link-cme-course',
			locale,
			showWhenFeatureAvailable: 'mediathek'
		}),
		...getHeaderLink({
			url: cmeOnDemandCoursesUrlByDomain,
			localeId: 'common.header.link-cme-on-demand-course',
			locale,
			showWhenFeatureFlagAvailable: (flags) => flags.onDemandAccreditation
		}),
		{
			localeId: 'common.header.link-topics',
			href: MEDIATHEK_URLS.topics,
			showWhenFeatureAvailable: 'mediathek',
			mediaQuery: 'mobile'
		},
		...getHeaderLink({
			url: livestreamURLByDomain,
			localeId: 'common.header.link-livestream',
			locale
		}),
		...getHeaderLink({
			url: webupUrlByDomain,
			localeId: 'common.header.link-webup',
			hideWhenFeatureAvailable: 'mediathek',
			locale
		}),
		{
			localeId: 'common.header.link-webup',
			href: MEDIATHEK_URLS.webup,
			showWhenFeatureAvailable: 'mediathek'
		},
		{
			localeId: 'common.header.link-summed-up',
			href: MEDIATHEK_URLS.summedup,
			showWhenFeatureAvailable: 'mediathek'
		},
		{
			localeId: 'common.header.link-skills',
			href: MEDIATHEK_URLS.skills,
			showWhenFeatureAvailable: 'mediathek'
		},
		{
			localeId: 'common.header.link-guidelines',
			href: MEDIATHEK_URLS.guidelines,
			showWhenFeatureAvailable: 'mediathek'
		},
		{
			localeId: 'common.header.link-partnerforum',
			href: CATALOG_URLS.partners + '/videos',
			showWhenFeatureAvailable: 'partnerForum'
		}
	],
	[
		...getHeaderLink({
			url: scientificBoardUrlByDomain,
			localeId: 'common.header.scientific-board',
			locale
		}),
		...getHeaderLink({
			url: partnerUrlByDomain,
			localeId: 'common.header.link-partner',
			showWhenFeatureAvailable: 'partnerLink',
			locale
		})
	],
	[
		{
			localeId: 'common.header.link-membership',
			href: BOOKING_URLS.memberships,
			showWhenFeatureAvailable: 'memberships'
		}
	],
	[
		{
			localeId: 'common.header.contact',
			href: contactLink(locale),
			mediaQuery: 'mobile'
		},
		{
			localeId: 'common.header.faq',
			href: faqLink(locale),
			mediaQuery: 'mobile'
		}
	]
];

export const headerLinks = (
	countryCode: CountryCode,
	isMobile: boolean,
	locale: Locale,
	flags: LDFlagSet
): HeaderLinksProps['menuItems'] => {
	return getDefaultLinks(locale)
		.map((each) =>
			each
				.filter((menu) => {
					if (menu.showWhenFeatureAvailable) {
						return isFeatureEnabled(menu.showWhenFeatureAvailable, countryCode);
					}
					if (menu.showWhenFeatureFlagAvailable) {
						return menu.showWhenFeatureFlagAvailable(flags);
					}
					return true;
				})
				.filter(
					(menu) =>
						!menu.hideWhenFeatureAvailable ||
						!isFeatureEnabled(menu.hideWhenFeatureAvailable, countryCode)
				)
				.filter((menu) => menu.mediaQuery !== (isMobile ? 'desktop' : 'mobile'))
		)
		.filter((each) => each.length > 0);
};

export const userSubMenuLinks = (locale: Locale) => [
	[
		{
			localeId: 'common.header.my-cme',
			href: USER_URLS.dashboard
		},
		{
			localeId: 'common.header.user-navbar.popover.user-management',
			href: USER_URLS.profile
		},
		{
			localeId: 'common.header.user-navbar.popover.membership',
			href: USER_URLS.dashboardMembership
		}
	],
	[
		{
			localeId: 'common.header.user-navbar.popover.logout',
			onClick: USER_URLS.logout({ locale })
		}
	]
];

export const useHeaderSearch = () => {
	const router = useRouter<HeaderQueryParams>();
	const {
		query: { search: searchQueryParam = null },
		setQuery
	} = router;
	const [search, setSearch] = useState<string | null>(searchQueryParam);

	const onSearch = () => {
		router.push(
			createURL(
				`${MEDIATHEK_URLS.search}?search=${search?.length ? search : ''}&sortBy=RELEVANCE`,
				'catalog'
			).url
		);
	};

	const onSearchChange = (text: string) => {
		setSearch(text || null);
	};

	const onClear = () => {
		if (searchQueryParam === search) {
			setQuery({ search: null });
			router.push(MEDIATHEK_URLS.search);
		} else {
			setSearch(null);
		}
	};

	return {
		search,
		onSearch,
		onSearchChange,
		onClear
	};
};
