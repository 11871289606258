import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../theme';

export const speakerText = {
	fontSize: 14,
	fontStyle: 'normal',
	fontWeight: 500,
	lineHeight: '18.75px',
	letterSpacing: '0.75px',
	textAlign: 'left',
	color: theme.palette.label.main
} as const;

const useStyles = makeStyles(
	(theme) => ({
		description: {
			fontWeight: 400,
			lineHeight: '14px',
			marginTop: theme.spacing(1),
			color: theme.palette.label.main,
			...theme.typography.subtitle2
		},
		title: {},
		container: { display: 'flex', marginTop: theme.spacing(1) },
		details: {
			marginLeft: theme.spacing(2),
			display: 'flex',
			justifyContent: 'center'
		},
		detailsFull: {
			flexDirection: 'column',
			justifyContent: 'center'
		},
		largeAvatar: { width: theme.spacing(9), height: theme.spacing(9) },
		smallAvatar: { width: theme.spacing(3), height: theme.spacing(3) },
		speakerText,
		detailsSmall: {
			marginLeft: theme.spacing(1.5)
		}
	}),
	{ name: 'Speaker' }
);

export default useStyles;
