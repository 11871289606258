import dayjs, { ConfigType, Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const DateFormat = {
	date: 'DD.MM.YYYY',
	dateTime: 'DD.MM.YYYY HH:mm',
	time: 'HH:mm',
	db: 'YYYY-MM-DDTHH:mm:ss[Z]',
	dbShort: 'YYYY-MM-DD',
	// only to use when layout cannot guarantee single-line rendering
	dayAndMonthSingleLine: 'DD.[&nbsp;]MMMM',
	dayAndMonth: 'DD. MMMM',
	dayAndMonthShort: 'DD. MMM',
	dayMonthYear: 'DD. MMMM YYYY',
	monthAndYear: 'MMMM YYYY',
	monthAndWeekday: 'dddd, DD. MMMM'
} as const;

export type DateFormat = typeof DateFormat[keyof typeof DateFormat] | string;

export const parseDBDate = (
	dateString: ConfigType,
	format: DateFormat = DateFormat.db,
	keepUTC = false
): Dayjs => {
	const date = dayjs.utc(dateString, format);
	const result = keepUTC ? date : date.local();
	return result;
};

export const formatDateForDB = (date: Dayjs, format: DateFormat = DateFormat.db): string => {
	return date.utc().format(format);
};
