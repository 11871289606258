import useRouter from '../../../hooks/useRouter';
import { queryToUrlSearchParams } from '../../../utils';
export const Locales: Array<Locale> = ['de-CH', 'fr-CH'];

/**
 * This hook is useful for changing the locale in the URL and navigating accordingly.
 * Locales supported are fr-CH and de-CH
 */
export const useChangeLocale = () => {
	const { basePath, pathname, push, locale, query } = useRouter();

	/**
	 * Change the locale in the URL and navigate to the new locale if necessary.
	 * @param {string | null} newLocale - The new locale to set in the URL, or null to do nothing.
	 */
	const changeLocale = (newLocale: string | null) => {
		const strQuery = queryToUrlSearchParams(query).toString();
		if (newLocale) {
			if (locale?.includes(newLocale)) {
				return;
			}
			let newURL = null;
			if (locale === 'fr-CH') {
				// just remove the fr-CH from the url
				newURL = `${window.location.origin}${window.location.pathname
					.split('/')
					.filter((each) => each !== 'fr-CH')
					.join('/')}`;
			} else {
				// Append the new locale segment to the URL
				newURL = `${window.location.origin}${basePath}/fr-CH${pathname ?? ''}`;
			}
			push(`${newURL}${strQuery ? `?${strQuery}` : ''}`);
		}
	};

	return changeLocale;
};
