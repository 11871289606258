import {
	WebTracking,
	getMembershipCreditNameForItem,
	Membership,
	PartialBy,
	Types,
	Fragments,
	booleanFilter,
	formatTranslation,
	createUserFullName
} from '../index';
import { IntlShape } from 'react-intl';
import getMembershipDateRange from '../utils/getMembershipDateRange';
import { DateFormat, parseDBDate } from 'components';

export const transformMembershipToProduct = (
	membership: PartialBy<Membership, 'membershipType'>
): WebTracking.ProductAdded => ({
	category: 'MEMBERSHIP',
	variant: membership.membershipType || 'ONLINE',
	price: membership.price / 100,
	location: membership.country,
	sku: membership.cycle,
	specialties: [],
	main_speciality: '',
	name: formatTranslation(membership.name, { locale: 'de-DE' }) || ''
});

export const transformTopUpToProduct = (props: {
	topUp: Types.MembershipItem;
	locale: Locale;
	buyLink?: string;
	index?: number;
	membership: Types.Membership;
}): WebTracking.ProductAdded => {
	const { topUp, locale, index, buyLink, membership } = props;
	const accreditationPointsName = getMembershipCreditNameForItem(topUp, locale);
	const sku = `${topUp.amount}`;

	return {
		category: 'TOPUP',
		variant: membership.membershipType || 'ONLINE',
		sku,
		price: typeof topUp.price === 'number' ? topUp.price / 100 : -1,
		location: topUp.country,
		specialties: [],
		...(buyLink ? { url: buyLink } : undefined),
		...(typeof index === 'number' ? { position: index + 1 } : undefined),
		name: `TopUp | +${topUp.amount} ${accreditationPointsName}`,
		main_speciality: ''
	};
};

export const transformUserForIdentifying = (props: {
	user: Fragments.UserFieldsFragment;
	userMembership: Fragments.UserMembershipFieldsFragment | null;
	intl: IntlShape;
}): {
	academic_degree_postfix?: string;
	academic_degree_prefix?: string;
	addresses: Array<{
		apartment_number?: string;
		city?: string;
		country?: string;
		full_name?: string;
		is_personal?: boolean;
		postal_code?: string;
		street?: string;
	}>;
	company_id?: string;
	country?: string;
	department?: string;
	email: string;
	facebook_url?: string;
	field_of_activity?: 'CLINIC_DOCTOR' | 'OTHER' | 'SETTLED_DOWN';
	first_name?: string;
	gender?: 'FEMALE' | 'MALE';
	hcp_accepted?: boolean;
	indication?: string;
	last_name?: string;
	linkedin_url?: string;
	medical_identity_number?: string;
	membership?: string;
	// DateTime
	membership_end?: string;
	// DateTime
	membership_start?: string;
	mobile_phone_number?: string;
	occupation?: string;
	occupation_type?: Types.OccupationType;
	phone_number?: string;
	phone_number_private?: string;
	position?: string;
	salutation?: string;
	specialities?: Array<string>;
	specializations?: Array<string>;
	state?: string;
	ub_account_code?: string;
	ub_account_type?: string;
	user_id: string;
} => {
	const { user, userMembership, intl } = props;
	const { locale } = intl;
	const academicDegree = user.academicDegree?.description.find((item) => item.locale === locale);

	const academicDegreePrefix =
		academicDegree && !academicDegree.isSuffix ? academicDegree.value : undefined;
	const academicDegreePostfix =
		academicDegree && academicDegree.isSuffix ? academicDegree.value : undefined;

	const fullName = createUserFullName(user, intl);

	return {
		...(academicDegreePrefix ? { academic_degree_prefix: academicDegreePrefix } : {}),
		...(academicDegreePostfix ? { academic_degree_postfix: academicDegreePostfix } : {}),
		addresses: (user.addresses || []).filter(booleanFilter).map((address) => ({
			...(address.apartmentNumber
				? { apartment_number: address.apartmentNumber }
				: undefined),

			...(address.city ? { city: address.city } : undefined),

			...(address.country ? { country: address.country } : undefined),

			...(address.fullName ? { full_name: address.fullName } : undefined),

			...(address.isPersonal ? { is_personal: address.isPersonal } : undefined),

			...(address.postalCode ? { postal_code: address.postalCode } : undefined),

			...(address.street ? { street: address.street } : undefined)
		})),
		...(user.company ? { company_id: user.company.id } : undefined),
		...(user.country ? { country: user.country } : undefined),
		email: user.email,
		...(user.fieldOfActivity ? { country: user.fieldOfActivity } : undefined),
		...(user.firstName ? { first_name: user.firstName } : undefined),
		...(user.gender ? { gender: user.gender } : undefined),
		...(user.healthProfessionalConfirmation
			? { hcp_accepted: user.healthProfessionalConfirmation }
			: undefined),
		...(user.lastName ? { last_name: user.lastName } : undefined),
		...(user.medicalIdentificationNumber
			? { medical_identity_number: user.medicalIdentificationNumber }
			: undefined),
		...(userMembership && userMembership.expiresAt
			? {
					membership: formatTranslation(userMembership.membership.name, { locale }) || '',
					membership_end: userMembership.expiresAt || '',
					membership_start: getMembershipDateRange(
						userMembership.expiresAt,
						userMembership.cycle,
						userMembership.initialStartedAt
					).startDate.format(DateFormat.db)
			  }
			: undefined),
		...(user.mobile ? { mobile_phone_number: user.mobile } : undefined),
		...(user.occupationType ? { occupation_type: user.occupationType } : undefined),
		...(fullName ? { salutation: fullName } : undefined),
		...(user.specialities.length > 0
			? { specialities: user.specialities.filter(booleanFilter).map((item) => item.code) }
			: undefined),
		...(user.ubAccountCode ? { ub_account_code: user.ubAccountCode } : undefined),
		...(user.accountType ? { ub_account_type: user.accountType } : undefined),

		user_id: user.id
	};
};

export const getContentNameForTracking = (
	contentForAnalytics: Pick<
		Fragments.ContentForAnalyticsFieldsFragment,
		'brand' | 'city' | 'product' | 'specialities' | 'startDateTime'
	>,
	locale: Locale
) => {
	const brand = contentForAnalytics.brand || 'UPDATE_REFRESHER';

	const city = contentForAnalytics.city as Record<Locale, string> | null;
	const cityTitle = formatTranslation(city, { locale }) || '';

	const speciality = contentForAnalytics.product?.code || contentForAnalytics.specialities[0];
	const date = parseDBDate(contentForAnalytics.startDateTime).format('MM.YY');
	const name = [speciality, brand, cityTitle, date].filter(booleanFilter).join(' ');
	return name;
};
