import React from 'react';
import { Dropdown, Icon } from 'components';

import useStyles from './LocaleSwitch.styles';
import { getLanguageFromLocale } from '../../../utils/locale';
import isFeatureEnabled from '../../../utils/isFeatureEnabled';
import { getCountryCode } from '../../../utils';
import { useChangeLocale, Locales } from './LocaleSwitch.utils';
import { useRouter } from '../../../hooks';

const LocaleSwitch = () => {
	const { locale } = useRouter();
	const changeLocale = useChangeLocale();
	const isAvailable = isFeatureEnabled('languageSwitch', getCountryCode(locale));
	const classes = useStyles();

	if (!isAvailable) {
		return null;
	}
	return (
		<Dropdown
			data-testid="locale-switch"
			value={locale}
			startIcon={<Icon className={classes.dropdownIcon} icon="language" />}
			options={Locales.map((each) => ({
				localeId: each,
				id: each
			}))}
			minimal
			titleLocaleId={locale ? getLanguageFromLocale(locale) : ''}
			onChange={changeLocale}
			className={classes.root}
			classes={{
				minimalText: classes.dropdownText,
				minimalTextField: classes.dropdown,
				inputBaseRoot: classes.inputBaseRoot
			}}
		/>
	);
};

export default LocaleSwitch;
